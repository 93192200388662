<template>
  <div class='day-count'>
    <span
      class='digit'
      v-for="(digit, index) in digits"
      :key="index"
    >{{ digit }}</span>
    </div>
</template>

<script>
export default {
  name: 'DayCount',
  props: {
    count: { type: Number },
  },
  computed: {
    digits() {
      const digits = (this.count || 0).toString().split('');
      const realDigits = digits.map(Number);
      return realDigits;
    },
  },
};
</script>

<style scoped lang='scss'>
.day-count {
  font-size: 10rem;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;

  .digit {
    border-radius: 2rem;
    border: 5px solid #000;
    background-color: #fff;
    color: #000;
    user-select: none;
    margin: 5px;
    min-width: 10vw;
    font-weight: bold;
  }
}
</style>
