<template>
  <div id="app">
    <div>
      <h1 class='lede'>It has been</h1>
    </div>
    <div>
      <DayCount :count='count'/>
    </div>
    <div>
      <h1>{{days}}</h1>
    </div>
    <div>
      <div class="caption">since <a href="https://twitter.com/pinski1_">@pinski1_</a> last started building a new bot</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import DayCount from './components/DayCount.vue';

export default {
  name: 'App',
  data: () => ({
    count: null,
  }),
  computed: {
    days() {
      return this.count === 1 ? 'day' : 'days';
    },
  },
  components: {
    DayCount,
  },
  beforeMount() {
    this.getDayCount();
  },
  methods: {
    getDayCount() {
      axios.get(process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:3000' : 'https://dayssincebotapi.robberwick.com')
        .then((response) => {
        // handle success
          this.count = response.data.count;
        })
        .catch(() => {
        // handle error
        })
        .then(() => {
        // always executed
          setTimeout(() => {
            this.getDayCount();
          }, 60000);
        });
    },
  },
};
</script>

<style lang='scss'>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  display: flex;
  flex-direction: column;

  .lede {
    margin: 5vh;
  }

  .caption {
    margin: 3vh;
  }

}
</style>
